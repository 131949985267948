import React from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import NavBar from "../../components/NavBar/NavBar"

const navRoadArray = [
  {
    name: "Privacy Policy",
    link: "/privacy/",
  },
]

function TermsandConditions(props) {
  const NoADS = props.pageContext
  return (
    <Layout location={props.location} NoADS={NoADS}>
      <SEO
        title="Unitpedia.com - Privacy Policy"
        description="UnitPedia Privacy Policy."
      />
      <NavBar listPages={navRoadArray} removeADS={true} />

      <section className="container">
        <h1>Privacy Policy</h1>

        <p>Welcome to unitpedia.com!</p>
        <br />
        <h2>General</h2>
        <br />
        <p>
          We don't sell, rent or share collected information from this website
          in ways different from what is written here.
        </p>
        <br />
        <h2>Google Analytics</h2>
        <br />
        <p>
          UnitPedia uses Google Analytics to monitor site usage in order to
          improve the quality of the pages. Google Analytics uses cookies.
          Google Analytics collects statistical, non-personal data.
        </p>
        <a
          href="https://policies.google.com/technologies/partner-sites"
          rel="noopener noreferrer"
          aria-label="google-analytics"
        >
          How google Analytics use data
        </a>
        <br />
        <a
          href="https://policies.google.com/privacy"
          rel="noopener noreferrer"
          aria-label="google-privacy-policy"
        >
          Google privacy policy
        </a>
        <br />
        <a
          href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
          rel="noopener noreferrer"
          aria-label="google-Analytics-cookie"
        >
          Google Analytics cookie usage
        </a>
        <br />
        <br />
        <h2>Data!</h2>
        <br />
        <p>
          UnitPedia may save user's settings or user's application data in the
          browser's local storage and terminally not in our server, so next time
          the user will enter the data will be available to the user, we don't
          have any information about the user.
        </p>
        <br />
        <h2>Social Share Buttons</h2>
        <br />
        <p>
          UnitPedia includes social share buttons. On pressing a share button,
          it will share information, like URL, TEXT, and other user contents to
          the social web.
        </p>
        <br />

        <h2>Changes to this Privacy Policy</h2>
        <br />
        <p>UnitPedia privacy policy may change from time to time.</p>
        <br />
        <h2>Contact information</h2>
        <br />
        <p>
          For questions, you may email to{" "}
          <a href="mailto:contact@unitpedia.com">Contact@unitpedia.com</a>
        </p>
        <br />
      </section>
    </Layout>
  )
}

export default TermsandConditions
